<script>
import Callback from "@/views/Callback.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  extends: Callback,
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    ...mapMutations(["setToken"]),
    generateToken(params) {
      return new Promise((resolve, reject) => {
        if (!Object.keys(params).length) {
          return reject("INVALID_CALLBACK");
        }
        return this.$axios
          .get("auth/callback/ms", { params })
          .then(({ data }) => {
            this.setToken(data.token);
            return resolve(data.token);
          })
          .catch(({ response }) => {
            return reject(response.data.code);
          });
      });
    },
  },
};
</script>
